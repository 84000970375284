<template>
  Cost per Borrow
  <span
    v-tooltip="'Ad spend / Total Borrows. This tells you how much is your cost per book borrowed.'"
    class="pi pi-info-circle mx-1"
  />
</template>

<script>
export default {};
</script>
